import {
    AlertSeverity,
    Box,
    BoxProps,
    BoxRadius,
    Button,
    ButtonStyle,
    ContentBlock,
    ContentBlockRow,
    ContentBlockRowElementRight,
    Formatter,
    InputDate,
    Loadable,
    SearchField,
    SearchToolbar,
    Select
} from "@sirdata/ui-lib";
import {FC, useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {buildAndDownloadCSV} from "../../common/utils/portal";
import {ChartContainer, ChartLine} from "../../component/widget";
import {CHART_DATASET_DEFAULT_STYLES, GraphData} from "../../utils/chart_options";
import {Container} from "../../api/model/container/Container";
import {session} from "../../api/ApiSession";
import {STAT_PERIODS, StatPeriod, StatPeriodItemFilter, defaultStatPeriod, getStatPeriodFilter} from "../../api/model/stat/StatPeriod";
import {TranslationPortalFile} from "../../utils/constants";
import {UIEventManager} from "../../common/utils/UIEventManager";

type ContainerDetailsAnalyticsProps = {
    container: Container;
}

const ContainerDetailsAnalytics: FC<ContainerDetailsAnalyticsProps> = ({container}) => {
    const {t: textAnalytics} = useTranslation(TranslationPortalFile.ANALYTICS);

    const [dataHits, setDataHits] = useState<GraphData | undefined>(undefined);
    const [totalHits, setTotalHits] = useState(0);

    const [period, setPeriod] = useState<StatPeriod>(defaultStatPeriod.type);
    const [periodInterval, setPeriodInterval] = useState<StatPeriodItemFilter>(getStatPeriodFilter(defaultStatPeriod.type));

    const [isLoading, setLoading] = useState(true);

    const loadData = useCallback(async function () {
        try {
            setLoading(true);

            const dataHits = await session.restStat.getDailyHits(periodInterval.start, periodInterval.end, container.id);

            setDataHits({
                labels: dataHits.map((it) => it.date),
                datasets: [{
                    label: textAnalytics("label.hits"),
                    data: dataHits.map((it) => it.total_hits),
                    ...CHART_DATASET_DEFAULT_STYLES
                }]
            });
            setTotalHits(dataHits.reduce((total, {total_hits}) => total + total_hits, 0));
        } catch (e) {
            UIEventManager.alert(textAnalytics("error.get_analytics"), AlertSeverity.DANGER);
        } finally {
            setLoading(false);
        }
    }, [container.id, periodInterval.start, periodInterval.end, textAnalytics]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const handleChangePeriod = (period: StatPeriod) => {
        setPeriod(period);
        if (period !== StatPeriod.CUSTOM) {
            setPeriodInterval(getStatPeriodFilter(period));
        }
    };

    const downloadCSVFile = () => {
        const fileTitle = textAnalytics("file.title", {name: container.name});
        const headers = [textAnalytics("file.column.date"), textAnalytics("file.column.hits")];
        let rows: string[][] = [];

        if (dataHits) {
            for (let i = 0; i < dataHits.labels.length; i++) {
                rows.push([dataHits.labels[i], dataHits.datasets[0].data[i].toString()]);
            }
        }

        buildAndDownloadCSV(fileTitle, headers, rows);
    };

    return (
        <ContentBlock header={{title: {label: textAnalytics("evolution_by_hits")}}}>
            <SearchToolbar>
                <SearchField label={textAnalytics("label.period")}>
                    <Select
                        value={period}
                        options={[
                            ...STAT_PERIODS.map(({type}) => ({value: type, label: textAnalytics(`option.${type}`)})),
                            {value: StatPeriod.CUSTOM, label: textAnalytics(`option.${StatPeriod.CUSTOM}`)}
                        ]}
                        onChange={(option) => handleChangePeriod(option?.value as StatPeriod)}
                    />
                </SearchField>
                {period === StatPeriod.CUSTOM &&
                <>
                    <SearchField label={textAnalytics("label.from")}>
                        <InputDate
                            value={periodInterval.start}
                            onChange={(value) => setPeriodInterval((prev) => ({...prev, start: value}))}
                            max={periodInterval.end}
                        />
                    </SearchField>
                    <SearchField label={textAnalytics("label.to")}>
                        <InputDate
                            value={periodInterval.end}
                            onChange={(value) => setPeriodInterval((prev) => ({...prev, end: value}))}
                            min={periodInterval.start}
                        />
                    </SearchField>
                </>
                }
            </SearchToolbar>
            <Loadable loading={isLoading}>
                <ContentBlock cssClass="analytics__hits">
                    <Box {...BoxProps.SECTION_BLOCK} radius={BoxRadius.MD}>
                        <div className="analytics__hits__metric">
                            <span className="analytics__hits__metric__header">{textAnalytics("metric.hits_total")}</span>
                            <span className="analytics__hits__metric__value">{Formatter.formatNumber(totalHits)}</span>
                        </div>
                        <ChartContainer height="35rem">
                            <ChartLine data={dataHits}/>
                        </ChartContainer>
                    </Box>
                    <ContentBlockRow>
                        <ContentBlockRowElementRight>
                            <Button
                                style={ButtonStyle.PRIMARY_MIDNIGHT}
                                icon={{name: "file_download"}}
                                onClick={downloadCSVFile}
                                children={textAnalytics("action.csv_export")}
                            />
                        </ContentBlockRowElementRight>
                    </ContentBlockRow>
                </ContentBlock>
            </Loadable>
        </ContentBlock>
    );
};

export default ContainerDetailsAnalytics;
